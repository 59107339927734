import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../config/config';

ReactGA.initialize(googleAnalyticsTrackingId);

export function trackScreen(screen = '') {
  const normalizedScreenName = screen.endsWith('/') ? screen : `${screen}/`;
  if (normalizedScreenName.includes('mot-de-passe')) return;
  ReactGA.pageview(normalizedScreenName);
  if (typeof fbq === 'function') {
    fbq('track', 'PageView'); // eslint-disable-line no-undef
    fbq('track', 'ViewContent', { content_name: normalizedScreenName }); // eslint-disable-line no-undef
  }
}

export function trackEvent(category, action, label, value) {
  const event = { category: String(category), action: String(action) };
  if (typeof label === 'string') { event.label = label; }
  if (typeof value === 'number') { event.value = value; }
  ReactGA.event(event);
}

export function trackUser({ firstname, lastname, email, phone, createdAt, _id, intercomHashWeb }) {
  const fullname = `${firstname} ${lastname}`;
  window.intercomSettings = {
    app_id: 'fny53b6d',
    name: fullname,
    email,
    phone,
    id: _id,
    user_id: _id,
    created_at: createdAt,
    user_hash: intercomHashWeb,
  };

  if (typeof window.Intercom === 'function') {
    window.Intercom('update', window.intercomSettings);
  }

  if (typeof FS !== 'undefined') { // FS not defined on localhost and on staging
    FS.setUserVars({ displayName: fullname, email });
  }

  ReactGA.set({ userId: _id });
}

// Do not update Intercom here : Intercom has a threshold of 20 udpates / session
// this method can be call several times, e.g. on each change of email input
export function trackUserEmail({ email }) {
  if (typeof FS !== 'undefined') { // FS not defined on localhost and on staging
    FS.setUserVars({ email });
  }
}

export function setProperty(key, value) {
  ReactGA.set({ [key]: value });
}
